/**
 * other than Base, all of these match up to paths
 * defined in server controllers
 */
enum URLs {
  Base = '/api/proxy',
  Proxy = '/publishing/proxy',
  LogoutUser = '/api/auth/logout',
  RpaOpsDashboard = '/rpa-dashboard/ui/dashboard',
  RpaOpsAddVerification = '/rpa-dashboard/ui/verification/addentry',
  RpaOpsSignOffData = '/rpa-dashboard/ui/dashboard/signoffdata',
  RpaOpsSignOffHistory = '/rpa-dashboard/ui/dashboard/signoffhistory',
  RpaOpsDataReset = '/rpa-dashboard/ui/resetmockdata',
  RpaOpsSignOff = '/rpa-dashboard/ui/signoff/v2',
  RpaOpsUnassignedBots = '/rpa-dashboard/ui/bot/unassigned',
  RpaOpsSignOffEffectiveDates = '/rpa-dashboard/ui/dashboard/signoffdata/effectivedates',
  RpaOpsSignOffDetailsBySignOffId = '/rpa-dashboard/ui/dashboard/signoffDetailsBySignoffid',
  RpaOpsAddVerificationComment = '/rpa-dashboard/ui/verification/addVerificationComment',
  RpaOpsSignOffFilesCaptureNames = '/rpa-dashboard/ui/signoff/files/capturenames',
  RpaOpsSharepointAuthenticate = '/rpa-dashboard/ui/signoff/files/token',

  RpaOpsBlockingTicket = '/rpa-dashboard/ui/openblockingticket',
  RpaOpsIntermittentTicket = '/rpa-dashboard/ui/openintermittentticket',
  RpaOpsNonBlockingTicket = '/rpa-dashboard/ui/opennonblockingticket',
  RpaOpsLogicalBot = '/rpa-dashboard/ui/logicalbot',
  RpaOpsLogicalBotCategories = '/rpa-dashboard/ui/dashboard/logicalbot/:logicalBotId/categories',
  RpaOpsLogicalBotAssignees = '/rpa-dashboard/ui/dashboard/logicalbot/:logicalBotId/assignees',
  RpaOpsLogicalBotCategory = '/rpa-dashboard/ui/dashboard/logicalbot/:logicalBotId/categories/:categoryId',
  RpaOpsLogicalBotAssignee = '/rpa-dashboard/ui/dashboard/logicalbot/:logicalBotId/assignees/:assigneeId',
  RpaOpsEditLogicalBotHistory = '/rpa-dashboard/ui/editlogicalbothistory',
  RpaOpsScheduledReportList = '/rpa-dashboard/ui/dashboard/notifications/email/reports/list',
  RpaOpsScheduledReportCreate = '/rpa-dashboard/ui/dashboard/notifications/email/reports/new',
  RpaOpsScheduledReportEdit = '/rpa-dashboard/ui/dashboard/notifications/email/reports/edit/:reportName',
  RpaOpsScheduledReportView = '/rpa-dashboard/ui/dashboard/notifications/email/reports/view/:reportName',
  RpaOpsScheduledReportDelete = '/rpa-dashboard/ui/dashboard/notifications/email/reports/edit/:reportName',
  RpaOpsEditBotRunner= '/rpa-dashboard/ui/editBotRunner',
  UserInfo = '/functions/auth/user',

  PeoplePickerSearch = '/external-services/search/sinequa',
}

export function getProxyUrl(url: string): string {
  return location.origin + URLs.Base + URLs.Proxy + '?url=' + encodeURIComponent(url);
}

export function getPeoplePickerUrl(): string {
  return location.origin + URLs.Base + URLs.PeoplePickerSearch;
}

export default URLs;
